<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <BlockGroup :label="'Model Positioning'">
          <InputWrapper
            type="slider"
            :label="'Scale'"
            :value="getSetting('world.scale')"
            :min="scaleMin"
            :max="scaleMax"
            :step="scaleStep"
            @change="onChange('world.scale', $event)"
          />

          <InputWrapper
            type="position"
            :label="'Position'"
            :values="worldPositions"
            :short="true"
            @change="onChangeWorldPosition"
          />

          <InputWrapper
            type="position"
            :label="'Rotation'"
            :values="worldRotations"
            :min="rotateMin"
            :max="rotateMax"
            :short="true"
            @change="onChangeWorldRotation"
          />

          <InputWrapper
            type="button"
            :simple="true"
            :value="'Set to Defaults'"
            @change="onPositionReset"
          />
        </BlockGroup>
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '../GuiSettingMixin'

export default {
  name: 'WorldModelPositioningBlock',

  mixins: [mixin],

  data() {
    return {
      scaleMin: 0.1,
      scaleMax: 2,
      scaleStep: 0.1,

      rotateMin: -Math.PI,
      rotateMax: Math.PI
    }
  },

  computed: {
    worldPositions() {
      return {
        x: this.getSetting('world.position.x'),
        y: this.getSetting('world.position.y'),
        z: this.getSetting('world.position.z')
      }
    },

    worldRotations() {
      return {
        x: this.getSetting('world.rotation.x'),
        y: this.getSetting('world.rotation.y'),
        z: this.getSetting('world.rotation.z')
      }
    }
  },

  methods: {
    onChangeWorldPosition(values) {
      if (values.x !== undefined) this.onChange('world.position.x', values.x)
      if (values.y !== undefined) this.onChange('world.position.y', values.y)
      if (values.z !== undefined) this.onChange('world.position.z', values.z)
    },

    onChangeWorldRotation(values) {
      if (values.x !== undefined) this.onChange('world.rotation.x', values.x)
      if (values.y !== undefined) this.onChange('world.rotation.y', values.y)
      if (values.z !== undefined) this.onChange('world.rotation.z', values.z)
    },

    onPositionReset() {
      this.onChange('world.position.x', 0)
      this.onChange('world.position.y', 0)
      this.onChange('world.position.z', 0)

      this.onChange('world.rotation.x', 0)
      this.onChange('world.rotation.y', 0)
      this.onChange('world.rotation.z', 0)

      this.onChange('world.scale', 1)
    }
  }
}
</script>
